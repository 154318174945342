import React from 'react'

import { Layout } from '../layouts/Layout'

const NotFoundPage = () => (
  <Layout>
    <main className="single">
      <div className="container">
        <h1 className="page-title">Not Found</h1>
        <p>Sorry, but the page you were trying to view does not exist.</p>
      </div>
    </main>
  </Layout>
)

export default NotFoundPage
